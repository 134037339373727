import React, { useEffect } from 'react';
import { Box, Card, CardContent, Divider, Grid, Link, Pagination, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import { IMake } from 'Communication/models/Make';
import { GetNumberRegisteredGroupedByMake } from 'Communication/MakeModelCommunication';
import Seo from 'Components/Seo/Seo';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(6)
    },
    title: {
      fontSize: '4em',
      fontWeight: 600,
    },
    divider: {
      margin: `${theme.spacing(2)} -${theme.spacing(4)}`,
      [theme.breakpoints.down('md')]: {
        margin: `${theme.spacing(2)} -${theme.spacing(1)}`,
      },
    }
  })
);

const itemsPerPage = 102;
const numberOfMakes = 4852; // https://opendata.rdw.nl/resource/m9d7-ebf2.json?$select=count(distinct merk)&$where=voertuigsoort in ('Personenauto', 'Bedrijfsauto', 'Bus', 'Oplegger', 'Motorfiets', 'Driewielig motorrijtuig', 'Motorfiets met zijspan', 'Bromfiets')

export default function MakeOverview() {
  AnalyticsHelper.LogScreenVisit("Make overview");
  const classes = useStyles();
  const [makes, setMakes] = React.useState<IMake[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const searchParams = new URLSearchParams(document.location.search);
  const page: number = +(searchParams.get('page') ?? '0');
  const [currentPage, setCurrentPage] = React.useState<number>(page);
  const history = useNavigate();

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    onPageChange();
  }

  async function onPageChange(newPage?: number) {
    let searchPage = newPage ?? 1;
    setIsLoading(true);
    setCurrentPage(searchPage);
    let result = await GetNumberRegisteredGroupedByMake(searchPage - 1, itemsPerPage);
    setMakes(result);

    if (newPage != undefined)
      history(`/make-overview?page=${newPage}`);
      
    setIsLoading(false);
  }

  function getPagination() {
    if (makes.length == 0)
      return <div></div>;

    return <Box mt={4}><Pagination disabled={isLoading} page={currentPage} color='primary' shape='rounded' siblingCount={5} onChange={(e, newPage) => onPageChange(newPage)} count={Math.ceil(numberOfMakes / itemsPerPage)} /></Box>
  }

  return (
    <div className={classes.container}>
      <Seo title='Merken overzicht' />
      <div>
        <span className={classes.title}>Merken</span>
      </div>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        {makes.map(make => {
          return (
            <Grid key={make.name} item xs={6} md={3} lg={2}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      <Link href={`/make/${make.name}`}>{make.name}</Link>
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                      {make.numberOfRegisteredVehicles} {make.numberOfRegisteredVehicles == 1 ? 'voertuig' : 'voertuigen'}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          )
        })}
      </Grid>
      <div>{getPagination()}</div>
    </div>
  );
}