import React from 'react';
import "firebase/compat/analytics";
import { TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";
import AnalyticsHelper from 'Helper/AnalyticsHelper';

interface IProps {
    countryCode: string;
    className: string;
}

export default function RegistrationInput(props: IProps) {
    const [inputValue, setInputValue] = React.useState('');
    let { countryCode, className } = props;

    const history = useNavigate();
    function onInputKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key.toLocaleLowerCase().includes('enter')) {
            logVehicleSearch();
            history(`/vehicle/${countryCode}/${inputValue}`);
        }
    }

    function logVehicleSearch() {
        AnalyticsHelper.LogEvent("vehicle_seach", { "registration": inputValue });
    }

    return(
        <TextField inputProps={{ inputMode: 'search' }} className={className} variant="standard" placeholder='Kenteken' value={inputValue} onChange={e => setInputValue(e.target.value.toUpperCase())} onKeyDown={onInputKeyDown} />
    );
}