import { MakeSummary } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";

function compareSummariesByName(a: MakeSummary, b: MakeSummary) {
    if (a.name == undefined || b.name == undefined)
        return 0;

    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
}

export function sortMakeSummariesByName(makeSummaries: MakeSummary[]) {
    return (Object.assign([], makeSummaries) as MakeSummary[]).sort(compareSummariesByName);
  }