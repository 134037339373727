import React, { useEffect } from 'react';
import "firebase/compat/storage";
import "firebase/compat/auth";
import { Theme, Box, Typography, Paper, Link, IconButton, DialogTitle, Dialog, DialogActions, DialogContentText, DialogContent, Button, Stack, Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import firebase from "firebase/compat/app";
import { GetVehiclesDetailsByLicenses } from 'Communication/VehicleCommunications';
import { IRdwVehicleDetails } from 'Communication/models/Vehicle';
import { DirectionsCar, RemoveCircle } from '@mui/icons-material';
import { FormatModelName, FormatRegistration } from 'Helper/FormatterHelper';
import { User, UserProfileOwnedVehicle, Vehicle } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { GetOwnedVehicleName } from 'Helper/OwnedVehicleHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultCarIcon: {
      height: '100%',
      width: '90%',
      color: theme.palette.primary.main,
    },
    vehicleCard: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      maxWidth: 400,
      minWidth: 350,
    },
    vehicleDetailsBar: {
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      color: 'white',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0
    }
  })
);

interface IProps {
  user?: User;
  isProfileOwner: boolean;
}

export default function UserProfile(props: IProps) {
  const classes = useStyles();
  const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();
  const [photoPaths, setPhotoPaths] = React.useState<string[]>([]);

  const [ownedVehicles, setOwnedVehicles] = React.useState<UserProfileOwnedVehicle[]>([]);
  const [vehiclesDetails, setVehiclesDetails] = React.useState<IRdwVehicleDetails[]>([]);
  const [ownedVehicleCount, setOwnedVehicleCount] = React.useState<number>(0);
  const [vehicleToRemoveId, setVehicleToRemoveId] = React.useState<string>();
  let { user, isProfileOwner } = props;

  useEffect(() => {
    initialize();
  }, [user]);

  async function initialize() {
    await new Promise(resolve => setTimeout(resolve, 500));
    if (user === undefined)
      return;

    let vehicleResult = (await VoertuigVinderApi.getUserProfileOwnedVehiclesByUserId(user.id!)).filter(vr => vr.endedOn == undefined);
    var registrations = vehicleResult.map(v => v.vehicleRegistration!);
    var vehicleDetails = await GetVehiclesDetailsByLicenses(registrations);
    setVehiclesDetails(vehicleDetails);

    var activeStorageRef = storageRef ?? firebase.storage().ref().child('Vehicles').child('Spots');
      
    if (storageRef === undefined){
      setStorageRef(activeStorageRef);
    }

    let photoPromises = vehicleResult?.filter(vehicle => vehicle.vehiclePrimaryPhoto != undefined && vehicle.vehiclePrimaryPhoto !== '').map(vehicle => activeStorageRef.child(vehicle?.vehiclePrimaryPhoto ?? '').getDownloadURL()) ?? [];
    const photoPathsResult: string[] = await Promise.all(photoPromises);
    setPhotoPaths(photoPathsResult);

    setOwnedVehicleCount(vehicleResult.length);
    setOwnedVehicles(vehicleResult);
  }

  async function removeFromGarage() {
    if (user === undefined || vehicleToRemoveId == undefined){
      return;
    }

    await VoertuigVinderApi.unclaimVehicleByRegistration(vehicleToRemoveId);

    setVehicleToRemoveId(undefined)
    initialize();
  }

  return (
      <Box>
        <Typography variant="h6">Garage</Typography>
        <Box component={Paper} p={1} style={{ overflowX: 'auto' }}>
          <Stack direction="row" spacing={2}>
            {ownedVehicles.length > 0 ? ownedVehicles.map(ov =>
            {
              let vehicleDetails = vehiclesDetails.filter(vd => vd.kenteken === ov.vehicleRegistration)[0];
              let primaryPhotoPath = photoPaths?.find(pp => pp.includes(ov.vehiclePrimaryPhoto ?? 'no_primary_photo'))

              return(
                <Card key={ov.vehicleId} className={classes.vehicleCard}>
                  <div style={primaryPhotoPath ? { } : {padding: '0 16px'}}><Link href={`/vehicle/${ov.vehicleCountryCode}/${ov.vehicleRegistration}`}>{ primaryPhotoPath != undefined ? <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={primaryPhotoPath} /> : <DirectionsCar className={classes.defaultCarIcon} /> }</Link></div>
                  <div className={classes.vehicleDetailsBar}>
                    <div>
                      <Typography fontSize='12px'>{GetOwnedVehicleName(ov, vehicleDetails)}</Typography>
                      <Typography fontSize='10px'>{<Link color='#FFFF' href={`/vehicle/${ov.vehicleCountryCode}/${ov.vehicleRegistration}`}>{FormatRegistration(ov.vehicleRegistration)}</Link>}</Typography>
                    </div>
                      {isProfileOwner &&
                      <IconButton
                        size='small'
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label='Verwijder uit garage'
                        onClick={() => setVehicleToRemoveId(ov.vehicleId)}
                      >
                        <RemoveCircle />
                      </IconButton>}
                </div>
                </Card>)}): <span>{isProfileOwner ? "Je hebt nog geen voertuigen. Zoek jouw voertuig op en claim deze zo snel mogelijk!" : `${user?.userName} heeft nog geen voertuigen`}</span>}
            </Stack>
          </Box>
          <Dialog
            open={vehicleToRemoveId !== undefined}
            onClose={() => setVehicleToRemoveId(undefined)}
          >
            <DialogTitle>Uit garage verwijderen</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Weet je zeker dat je dit voertuig uit je garage wilt verwijderen?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setVehicleToRemoveId(undefined)}>Annuleren</Button>
              <Button onClick={removeFromGarage} autoFocus>
                Verwijderen
              </Button>
            </DialogActions>
          </Dialog>
      </Box>
  );
}