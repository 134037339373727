import React, { useEffect } from 'react';
import "firebase/compat/storage";
import "firebase/compat/auth";
import { Theme, Box, Typography, Paper, Link, Stack, Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import firebase from "firebase/compat/app";
import { GetVehiclesDetailsByLicenses } from 'Communication/VehicleCommunications';
import { IRdwVehicleDetails } from 'Communication/models/Vehicle';
import { MoreHoriz } from '@mui/icons-material';
import { FormatRegistration } from 'Helper/FormatterHelper';
import { User, UserProfileVehicleSpot } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { getThumb400Id } from 'Helper/PhotoHelper';
import { useNavigate } from 'react-router-dom';
import { GetVehicleName } from 'Helper/VehicleSpotHelper';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultCarIcon: {
      height: '50%',
      width: '80%',
      marginBottom: '-30px',
      color: theme.palette.primary.main,
    },
    vehicleCard: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      minWidth: 400,
      maxWidth: 400,
    },
    addSpotCard: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      minWidth: 200,
      cursor: 'pointer',
    },
    vehicleDetailsBar: {
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      color: 'white',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0
    }
  })
);

interface IProps {
  user?: User;
  isProfileOwner: boolean;
}

export default function UserSpots(props: IProps) {
  const classes = useStyles();
  const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();
  const [photoPaths, setPhotoPaths] = React.useState<string[]>([]);
  const [currentUser, setCurrentUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());

  const [spots, setSpots] = React.useState<UserProfileVehicleSpot[]>([]);
  const [vehiclesDetails, setVehiclesDetails] = React.useState<IRdwVehicleDetails[]>([]);
  const [spotCount, setSpotCount] = React.useState<number>(0);
  let { user, isProfileOwner } = props;
  const history = useNavigate();

  FireStoreAuthHelper.OnUserSet(event => setCurrentUser(event.detail as User));

  useEffect(() => {
    initialize();
  }, [user]);

  async function initialize() {
    if (user === undefined)
      return;

    let spotsResult = await VoertuigVinderApi.getApprovedUserProfileVehicleSpotsByUserId(user.id!, 8, 1)
    let spotCount = await VoertuigVinderApi.getApprovedUserProfileVehicleSpotCountByUserId(user.id!)
    var registrations = spotsResult.map(v => v.vehicleRegistration!);
    var vehicleDetails = await GetVehiclesDetailsByLicenses(registrations);
    setVehiclesDetails(vehicleDetails);

    var activeStorageRef = storageRef ?? firebase.storage().ref().child('Vehicles').child('Spots-Thumb-400');
      
    if (storageRef === undefined){
      setStorageRef(activeStorageRef);
    }

    let photoPromises = spotsResult?.map(spot => activeStorageRef.child(spot != undefined ? getThumb400Id(spot.photoId!) : '').getDownloadURL()) ?? [];
    const photoPathsResult: string[] = await Promise.all(photoPromises);
    setPhotoPaths(photoPathsResult);

    setSpotCount(spotCount);
    setSpots(spotsResult);
  }

  return (
      <Box>
        <Typography variant="h6">Spots (<Link href={`/user/profile/${user?.id}/all-spots`}>{spotCount}</Link>)</Typography>
        <Box component={Paper} p={1} style={{ overflowX: 'scroll' }}>
          <Stack direction="row" spacing={2}>
            {spots.length > 0 ? spots.map(vs =>
            {
              let vehicleDetails = vehiclesDetails.filter(vd => vd.kenteken === vs.vehicleRegistration)[0];
              let primaryPhotoPath = photoPaths?.find(pp => pp.includes(getThumb400Id(vs.photoId!)))

              return(
                <Card key={vs.vehicleSpotId} className={classes.vehicleCard}>
                  <div><Link href={`/vehicle/${vs.vehicleCountryCode}/${vs.vehicleRegistration}`}><img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={primaryPhotoPath} /></Link></div>
                  <div className={classes.vehicleDetailsBar}>
                    <div>
                      <Typography fontSize='12px'>{GetVehicleName(vs, vehicleDetails)}</Typography>
                      <Typography fontSize='10px'>
                        {<Link color='#FFFF' href={`/vehicle/${vs.vehicleCountryCode}/${vs.vehicleRegistration}`}>{FormatRegistration(vs.vehicleRegistration)}</Link>} | {moment(vs.takenOn!).format('D MMMM YYYY')} | {vs.locationCity}
                      </Typography>
                    </div>
                </div>
                </Card>)}): <span>{isProfileOwner ? "Je hebt nog geen voertuigen gespot!" : `${user?.userName} heeft nog geen voertuigen gespot`}</span>}
                {spotCount > 8 && 
                <Card key='add-spot' className={classes.addSpotCard} onClick={() => history(`/user/profile/${user?.id}/all-spots`)}>
                  <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                    <MoreHoriz className={classes.defaultCarIcon} />
                    <span style={{textAlign: 'center'}}>Bekijk alle {spotCount} spots</span>
                  </Box>
              </Card>}
            </Stack>
          </Box>
      </Box>
  );
}